<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ 'Crear Usuarios automaticamente' }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->

                                <v-autocomplete
                                    v-model="editedItem.company.value"
                                    :items="companies"
                                    :label="$t('companies.selectCompany')"
                                    item-text="attributes.name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    :error-messages="editedItem.company.error"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="editedItem.userPrefix.value"
                                    class="purple-input"
                                    :label="$t('games.userNamePrefixCreate')"
                                    :error-messages="editedItem.userPrefix.error"
                                    @keyup="editedItem.userPrefix.error = ''"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="editedItem.countPlayer.value"
                                    type="number"
                                    class="purple-input"
                                    :label="$t('games.selectUsersCountCreate')"
                                    :error-messages="editedItem.countPlayer.error"
                                    :rules="[rules.required, rules.validNumber]"
                                    @keyup="editedItem.countPlayer.error = ''"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
// import GroupsSelect from '@/components/admin/groups/groupsSelect'

export default {
    // components: { GroupsSelect },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: 'admin/games/players/create_auto',
            rules: {
                ...rules
            },
            editedItem: {
                countPlayer: { value: '', error: '' },
                company: { value: '', error: '' },
                userPrefix: { value: '', error: '' }
            },
            isValid: true,
            serverCheck: false,
            companies: []

        }
    },

    computed: {

    },
    created() {
        this.fetchCompanies();
    },
    methods: {

        close() {
            this.$emit('update:dialog', false)
        },

        async save() {
            this.$store.dispatch('loading/start');

            var dataSubmit = {
                countPlayer: this.editedItem.countPlayer.value,
                company: this.editedItem.company.value
            }

            const userPrefix = this.editedItem.userPrefix.value;
            if (userPrefix) dataSubmit.userPrefix = userPrefix;

            const urlAppend = '/' + this.gameId

            const method = 'post'

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                })

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    this.$store.dispatch('snackbar/success', this.$t('common.createdOK'))
                }
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table');

            this.$store.dispatch('loading/end');
        },
        async fetchCompanies() {
            try {
                const result = await axios
                    .get("companies");

                this.companies = result.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>
